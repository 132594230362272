import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Product, ProductConfiguration } from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { ProductUtilsService } from '@core/services/product-utils.service';
import { ProductConfigurationsState } from '@core/store/product-configuration/product-configuration.state';
import { ProductsListState } from '@core/store/products/products.state';
import { NbDialogService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import {
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  of,
} from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { filter, map, share, switchMap, take } from 'rxjs/operators';
import { Partner, PaymentType } from '../../../@core/core.types';
import { ChangeProductPriceDialogContex } from '../../partner.types';
import { PartnerProductConfigurationsState } from '../../store/partner-product-configurations/partner-product-configurations.state';
import { PartnerProductPriceDialogComponent } from '../partner-product-price-dialog/partner-product-price-dialog.component';

const log = new Logger('PartnerProductsListComponent');
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-partner-products-list',
  templateUrl: './partner-products-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProductsListComponent implements OnInit, OnChanges {
  @ViewChild('deleteRowTemplate', { static: true })
  deleteRowTemplate: TemplateRef<any>;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('endUserPriceTemplate', { static: true })
  endUserPriceTemplate: TemplateRef<any>;
  @ViewChild('doctorsFeePriceTemplate', { static: true })
  doctorsFeePriceTemplate: TemplateRef<any>;
  @ViewChild('wholesalePriceTemplate', { static: true })
  wholesalePriceTemplate: TemplateRef<any>;
  @ViewChild('onlineFeePriceTemplate', { static: true })
  onlineFeePriceTemplate: TemplateRef<any>;

  @Select(PartnerProductConfigurationsState.getProductConfigurations)
  productConfigurations: Observable<string[]>;
  @Input()
  selectedPartner: Partner;
  selectedPartner$ = new ReplaySubject<Partner>(1);

  rows: Observable<Product[]> = null;

  dataTableConfiguration: DataTableConfiguration;

  isLoading = false;

  private partnerPaymentTypes$ = new BehaviorSubject<string[]>([]);

  constructor(
    private store: Store,
    private productUtils: ProductUtilsService,
    private nbDialogService: NbDialogService
  ) {}

  mapAndSortProducts(
    pairs
  ): { product: Product; productConfiguration: string }[] {
    const productsArray: {
      productConfiguration: string;
      product: Product;
    }[] = [];
    for (const key of Object.keys(pairs)) {
      productsArray.push({
        productConfiguration: key,
        product: pairs[key],
      });
    }

    return productsArray.sort((a, b) =>
      this.productUtils.productOrder(a.product.name, b.product.name)
    );
  }

  getProducts$(productConfigurations: string[]): {
    [key: string]: Observable<Product>;
  } {
    const observables = {};

    for (const id of productConfigurations) {
      observables[id] = this.resolveProductConfigurationById$(id).pipe(
        switchMap((productConfiguration) =>
          productConfiguration?.productId
            ? this.resolveProductById$(productConfiguration?.productId)
            : of(null)
        ),
        first((product) => !!product)
      );
    }

    return observables;
  }

  ngOnChanges(changes: SimpleChanges): void {
    log.debug('ngOnChanges');
    if (changes.selectedPartner) {
      this.selectedPartner$.next(this.selectedPartner);
      this.partnerPaymentTypes$.next(this.selectedPartner.paymentTypes);
    }
  }

  ngOnInit(): void {
    this.rows = this.productConfigurations.pipe(
      switchMap((productConfigurations) =>
        productConfigurations?.length
          ? combineLatest(
              productConfigurations.map((pcId: string) =>
                this.productUtils.getProductConfigurationById$(pcId).pipe(
                  switchMap((pc: ProductConfiguration) => {
                    if (pc.productId) {
                      return this.resolveProductById$(pc.productId);
                    }
                    return of(null);
                  })
                )
              )
            )
          : of([])
      ),
      map((products) =>
        products.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      )
    );

    const partnerPaymentTypes = this.partnerPaymentTypes$.value;

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      columns: [
        {
          label: 'partnersPage.tableColumns.name',
          propertyTemplate: this.nameTemplate,
        },
        partnerPaymentTypes.indexOf(PaymentType.Customer1) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Customer2) > -1
          ? {
              label: 'partnersPage.tableColumns.endUserPrice',
              propertyTemplate: this.endUserPriceTemplate,
            }
          : null,
        partnerPaymentTypes.indexOf(PaymentType.Customer1) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Customer2) > -1
          ? {
              label: 'partnersPage.tableColumns.doctorsFee',
              propertyTemplate: this.doctorsFeePriceTemplate,
            }
          : null,
        partnerPaymentTypes.indexOf(PaymentType.Partner) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Customer2) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Online2) > -1
          ? {
              label: 'partnersPage.tableColumns.wholeSalePrice',
              propertyTemplate: this.wholesalePriceTemplate,
            }
          : null,
        partnerPaymentTypes.indexOf(PaymentType.Online1) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Online2) > -1
          ? {
              label: 'partnersPage.tableColumns.onlineFee',
              propertyTemplate: this.onlineFeePriceTemplate,
            }
          : null,
      ].filter((column) => !!column),
      rows$: this.rows,
    };
  }

  resolveProductConfigurationById$(
    productConfigurationId: string
  ): Observable<ProductConfiguration> {
    if (productConfigurationId) {
      return this.store
        .select(ProductConfigurationsState.getProductConfigurationById)
        .pipe(
          map((filterByProductConfigurationId) =>
            filterByProductConfigurationId(productConfigurationId)
          ),
          first((item) => !!item),
          share()
        );
    }
    return of(null);
  }

  resolveProductById$(productId: string): Observable<Product> {
    if (productId) {
      return this.store.select(ProductsListState.getProduct).pipe(
        map((filterByProductId) => filterByProductId(productId)),
        filter((pc) => pc !== undefined),
        share()
      );
    }
    return of(null);
  }

  isProductAvailable(productId: string): Observable<boolean> {
    return this.store
      .select(ProductsListState.getCountryProductList)
      .pipe(
        map((productsByCountry) =>
          productsByCountry(
            this.selectedPartner.address
              ? this.selectedPartner.address.country
              : null
          )
        )
      )
      .pipe(
        take(1),
        map(
          (countryProducts: Product[]) =>
            !!countryProducts.find((product) => product.id === productId)
        )
      );
  }

  changeProductPrice(
    event: MouseEvent,
    product: Product,
    selectedPartnerExternalId: string
  ) {
    log.debug('changeProductPrice');
    this.nbDialogService.open(PartnerProductPriceDialogComponent, {
      context: {
        product,
        selectedPartnerExternalId,
      } as ChangeProductPriceDialogContex as any,
    });
  }
}
