<nb-card [nbSpinner]="isLoading$ | async" style="min-width: 800px">
  <nb-card-header class="position-relative">
    <p class="mb-0" [innerHTML]="modalTitle | translate"></p>
    <nb-icon (click)="dismiss()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="mb-4" *ngIf="missingNotificationProfiles?.length">
      <b
        >{{
          'notificationProfilesPage.form.missingNofificationProfilesText'
            | translate
        }}:
      </b>
      <div>
        {{ missingNotificationProfiles.join(', ') }}
      </div>
    </div>
    <form
      (ngSubmit)="addRecipient($event)"
      [formGroup]="notificationProfileForm"
      novalidate
    >
      <ng-container
        *appRequirePermission="'partners.doctors.notificationProfiles.editForm'"
      >
        <p
          class="caption mt-2 mb-1"
          translate="notificationProfilesPage.titleRecipients"
        ></p>
        <hr class="mt-0" />

        <div class="row">
          <div class="form-group col-sm-12 col-lg-12">
            <div class="d-flex">
              <div class="flex-fill">
                <input
                  fullWidth
                  nbInput
                  formControlName="recipient"
                  name="recipient"
                  id="recipient"
                  placeholder="{{
                    'notificationProfilesPage.form.placeholders.recipient'
                      | translate
                  }}"
                  data-cy="recipient-select"
                />
                <ng-container
                  *ngIf="c('recipient').invalid && c('recipient').touched"
                >
                  <p
                    class="caption status-danger"
                    *ngIf="c('recipient').errors?.pattern"
                    translate="common.errors.emailIsInvalid"
                  ></p>
                </ng-container>
              </div>

              <div>
                <button
                  nbButton
                  class="ms-2"
                  (click)="addRecipient($event)"
                  [disabled]="isLoading$ | async"
                  nbTooltip="{{
                    'notificationProfilesPage.tooltips.addRecipient' | translate
                  }}"
                  nbTooltipPlacement="left"
                  status="primary"
                  type="submit"
                  data-cy="add-recipient-btn"
                >
                  <nb-icon icon="plus-outline"></nb-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>

    <div class="row">
      <div class="col-sm-12 col-lg-12">
        <app-data-table
          [configuration]="dataTableConfiguration"
        ></app-data-table>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button
      *appRequirePermission="'partners.doctors.notificationProfiles.editForm'"
      nbButton
      class="status-primary"
      (click)="close()"
      [class.loading]="isLoading$ | async"
      [disabled]="isLoading$ | async"
      data-cy="save-btn"
    >
      {{ closeModalTitle | translate }}
    </button>
  </nb-card-footer>
</nb-card>
