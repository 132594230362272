<ng-container *ngIf="partner$ | async as selectedPartner">
  <nb-card class="mb-2">
    <nb-card-header class="position-relative">
      <p class="mb-0">
        Partner details
        <ng-container *ngIf="selectedPartner?.deactivated">
          <label class="partner-deactivated">Deactivated</label>
        </ng-container>
      </p>
      <nb-icon
        (click)="close()"
        icon="close"
        data-cy="partner-details-close-icon"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-end">
            <p class="caption mb-1" translate="common.titleBaseInformation"></p>
            <button
              nbButton
              class="p-1 mb-1"
              (click)="onEditPartner($event, selectedPartner)"
              nbTooltip="{{ 'partnersPage.tooltips.editPartner' | translate }}"
              nbTooltipPlacement="left"
              status="primary"
              size="small"
              type="submit"
              data-cy="edit-partner-btn"
            >
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </div>
          <hr class="mt-0" />
          <ng-container *appRequirePermission="'partners.details.view'">
            <div class="row mb-2">
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="name-row"
              >
                <label
                  for="name"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.name"
                ></label>
                <div class="col-8">
                  <span id="name">{{ selectedPartner.name }}</span>
                </div>
              </div>
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="language-row"
              >
                <label
                  for="language"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.language"
                ></label>
                <div class="col-8">
                  <span id="language">
                    <ng-container
                      *ngIf="
                        selectedPartner?.languageIds &&
                          selectedPartner?.languageIds?.length > 0;
                        else noItem
                      "
                    >
                      {{
                        selectedPartner.languageIds[0]
                          | displayName: (languages$ | async)
                      }}
                    </ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="id-row"
              >
                <label
                  for="name"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.id"
                ></label>
                <div class="col-8">
                  <span>{{ selectedPartner.id }}</span>
                </div>
              </div>
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="rating-row"
              >
                <label
                  for="language"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.rating"
                ></label>
                <div class="col-8">
                  <app-rating
                    [rating]="resolveRating(selectedPartner.properties)"
                    [itemId]="selectedPartner.id"
                    iconType="star"
                  >
                  </app-rating>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="partner-type-row"
              >
                <label
                  for="name"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.partnerType"
                ></label>
                <div class="col-8">
                  <span>{{ (partnerType$ | async) || '—' }}</span>
                </div>
              </div>
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="registered-row"
              >
                <label
                  for="language"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.registered"
                ></label>
                <div class="col-8">
                  <span
                    id="registered"
                    *ngIf="selectedPartner.registered?.timestamp; else noItem"
                  >
                    {{
                      moment(selectedPartner.registered.timestamp).format(
                        MOMENT_DATE_TIME_FORMAT
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="email-row"
              >
                <label
                  for="name"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.email"
                ></label>
                <div class="col-8">
                  <span>{{ selectedPartner.email }}</span>
                </div>
              </div>
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="payment-type-row"
              >
                <label
                  for="language"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.paymentType"
                ></label>
                <div class="col-8">
                  <span>{{ selectedPartner.paymentTypes }}</span>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="phone-number-row"
              >
                <label
                  for="name"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.phoneNumber"
                ></label>
                <div class="col-8">
                  <span>{{ selectedPartner.phoneNumber || '—' }}</span>
                </div>
              </div>
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="external-id-row"
              >
                <label
                  for="language"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.externalId"
                ></label>
                <div class="col-8">
                  <span
                    id="externalId"
                    *ngIf="subject$ | async as subject; else noItem"
                  >
                    [{{ subject.id }}] {{ subject.subjectName }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="account-manager-row"
              >
                <label
                  for="name"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.accountManager"
                ></label>
                <div class="col-8">
                  <span id="accountManager">
                    <ng-container
                      *ngIf="
                        resolveAccountManagerById$(
                          selectedPartner.accountManagerId
                        ) | async as accountManager;
                        else noItem
                      "
                    >
                      {{ accountManager.name }}
                    </ng-container>
                  </span>
                </div>
              </div>
              <div
                class="col-12 col-md-6 row align-items-center"
                *appRequirePermission="'partners.details.view.isOnline'"
                data-cy="online-partner-row"
              >
                <label
                  for="language"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.isOnlinePartner"
                ></label>
                <div class="col-8">
                  {{
                    resolvePropertyValue(
                      selectedPartner.properties,
                      'IsOnlinePartner'
                    ) === true
                      ? 'Yes'
                      : 'No'
                  }}
                </div>
              </div>
            </div>
          </ng-container>

          <p
            class="caption mb-1 mt-3"
            translate="partnersPage.titleAddress"
          ></p>
          <hr class="mt-0" />

          <div class="row mb-2">
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="street-row"
            >
              <label
                for="name"
                class="label col-4 mb-0 text-end"
                translate="partnersPage.partnersForm.street"
              ></label>
              <div class="col-8">
                <span>{{ selectedPartner.address?.street || '—' }}</span>
              </div>
            </div>
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="city-row"
            >
              <label
                for="name"
                class="label col-4 mb-0 text-end"
                translate="partnersPage.partnersForm.city"
              ></label>
              <div class="col-8">
                <span>{{ selectedPartner.address?.city || '—' }}</span>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="zip-code-row"
            >
              <label
                for="name"
                class="label col-4 mb-0 text-end"
                translate="partnersPage.partnersForm.zip"
              ></label>
              <div class="col-8">
                <span>{{ selectedPartner.address?.zip || '—' }}</span>
              </div>
            </div>
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="country-row"
            >
              <label
                for="name"
                class="label col-4 mb-0 text-end"
                translate="partnersPage.partnersForm.country"
              ></label>
              <div class="col-8">
                <span>{{ selectedPartner.address?.country || '—' }}</span>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="additional-info-row"
            >
              <label
                for="name"
                class="label col-4 mb-0 text-end"
                translate="partnersPage.partnersForm.additionalInfo"
              ></label>
              <div class="col-8">
                <span id="additionalInfo">
                  <ng-container
                    *ngIf="
                      resolvePropertyByKey(
                        selectedPartner.properties,
                        'AdditionalInfo'
                      ) as property;
                      else noItem
                    "
                  >
                    {{ property.value || '—' }}
                  </ng-container>
                </span>
              </div>
            </div>
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="region-row"
            >
              <label
                for="name"
                class="label col-4 mb-0 text-end"
                translate="partnersPage.partnersForm.region"
              ></label>
              <div class="col-8">
                <span id="region">
                  <ng-container
                    *ngIf="
                      resolvePropertyByKey(
                        selectedPartner.properties,
                        'Region'
                      ) as property;
                      else noItem
                    "
                  >
                    {{ property.value }}
                  </ng-container>
                </span>
              </div>
            </div>
          </div>

          <ng-container *appRequirePermission="'partners.list.partnerStorage'">
            <p class="caption mb-1 mt-3">
              {{ 'partnersPage.kitStock' | translate }}
            </p>
            <hr class="mt-0" />
            <div class="row mb-2">
              @for (kitType of KitTypes | keyvalue; track kitType) {
                @if (kitType.value !== KitTypes.PremiumPro) {
                  <div
                    class="col-12 col-md-6 row align-items-center mb-4"
                    data-cy="minimal-supply-row"
                  >
                    <div class="row mb-2">
                      <label for="name" class="label col-4 mb-0 text-end">{{
                        'partnersPage.partnersForm.kitType' | translate
                      }}</label>
                      <div class="col-8">
                        <span>{{
                          'kitsPage.kitsForm.kitTypes.' + kitType.value
                            | translate
                        }}</span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <label for="name" class="label col-4 mb-0 text-end">{{
                        'partnersPage.partnersForm.minimalSupply' | translate
                      }}</label>
                      <div class="col-8">
                        <span>{{
                          getMinimalSupply(selectedPartner, kitType.value)
                        }}</span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <label for="name" class="label col-4 mb-0 text-end">{{
                        'partnersPage.partnersForm.averageMonthlyVolume'
                          | translate
                      }}</label>
                      <div class="col-8">
                        <span>{{
                          getAvgMonthlySupply(
                            averageMonthlySupply$ | async,
                            kitType.value
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <label for="name" class="label col-4 mb-0 text-end">{{
                        'partnersPage.partnersForm.storage' | translate
                      }}</label>
                      <div class="col-8">
                        <span
                          [ngClass]="
                            isKitSupplySmallerThanStorage(
                              storage$ | async,
                              selectedPartner,
                              kitType.value
                            )
                              ? 'text-danger fw-bold'
                              : ''
                          "
                          >{{
                            getStorage(storage$ | async, kitType.value)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                }
              }
            </div>

            @if (selectedPartner?.lastSample) {
              <div class="row mb-2">
                <div class="col-12 col-md-6 row align-items-center mb-4">
                  <label for="name" class="label col-4 mb-0 text-end">{{
                    'partnersPage.partnersForm.lastSample' | translate
                  }}</label>
                  <div class="col-8">
                    <span>{{
                      moment(selectedPartner?.lastSample).format(
                        MOMENT_DATE_TIME_FORMAT
                      )
                    }}</span>
                  </div>
                </div>
              </div>
            }
          </ng-container>

          @if (
            resolvePropertyByKey(
              selectedPartner.properties,
              'PlasmaLabLocation'
            );
            as property
          ) {
            <p
              class="caption mb-1 mt-3"
              translate="partnersPage.awbModelInformation"
            ></p>
            <hr class="mt-0" />
            <div class="row mb-2">
              <div
                class="col-12 col-md-6 row align-items-center"
                data-cy="plasma-lab-row"
              >
                <label
                  for="name"
                  class="label col-4 mb-0 text-end"
                  translate="partnersPage.partnersForm.plasmaLabLocation"
                ></label>
                <div class="col-8">
                  <span>
                    {{
                      getPlasmaLabLocationLabel(property.value) ??
                        property.value
                    }}
                  </span>
                </div>
              </div>
            </div>
          }

          <p class="caption mb-1 mt-3">
            {{ 'partnersPage.otherInformation' | translate }}
          </p>
          <hr class="mt-0" />
          <div class="row mb-2">
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="online-reports-row"
            >
              <label for="name" class="label col-4 mb-0 text-end">{{
                'partnersPage.partnersForm.onlineReports' | translate
              }}</label>
              <div class="col-8">
                <span>{{
                  (selectedPartner.onlineReport
                    ? 'partnersPage.enabled'
                    : 'partnersPage.disabled'
                  ) | translate
                }}</span>
              </div>
            </div>
          </div>

          <ng-container *appRequirePermission="'partners.details.view.notes'">
            <ng-container *ngIf="selectedPartner.notes">
              <p
                class="caption mb-1"
                translate="partnersPage.partnersForm.notes"
              ></p>
              <hr class="mt-0" />

              <div class="row align-items-center mb-2" data-cy="notes-row">
                <div class="col-9 col-md-4">
                  <span id="notes">{{ selectedPartner.notes }}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      @if (
        resolvePropertyValue(selectedPartner.properties, 'IsOnlinePartner')
      ) {
        <ng-container *appRequirePermission="'partners.details.products'">
          <div class="row mb-1 mt-3 align-items-end">
            <p
              class="caption mb-0 col-9"
              translate="partnersPage.titleProducts"
              [translateParams]="{ partner: selectedPartner.name }"
            ></p>
            <div class="text-end col-3">
              <button
                *appRequirePermission="'partners.details.products.add'"
                nbButton
                class="btn-doctor-add p-1"
                (click)="addProduct($event, selectedPartner)"
                nbTooltip="{{
                  'partnersPage.tooltips.editProducts' | translate
                }}"
                nbTooltipPlacement="left"
                status="primary"
                size="small"
                type="submit"
                [disabled]="selectedPartner.deactivated"
                data-cy="edit-products-btn"
              >
                <nb-icon icon="cube-outline"></nb-icon>
              </button>
            </div>
          </div>
          <hr class="mt-0" />
          <div class="row">
            <div class="col-12">
              <div class="row align-items-center mb-2">
                <div class="col-12">
                  <app-partner-products-list
                    [selectedPartner]="selectedPartner"
                  >
                  </app-partner-products-list>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      }

      <ng-container *appRequirePermission="'partners.details.bundles'">
        <div class="row mb-1 mt-3 align-items-end">
          <p class="caption mb-0 col-9">
            {{
              'partnersPage.titleBundles'
                | translate: { partner: selectedPartner.name }
            }}
          </p>
          <div class="text-end col-3">
            <button
              *appRequirePermission="'partners.details.bundles.add'"
              nbButton
              class="btn-doctor-add p-1"
              nbTooltip="{{ 'partnersPage.tooltips.editBundles' | translate }}"
              nbTooltipPlacement="left"
              status="primary"
              size="small"
              type="submit"
              [disabled]="selectedPartner.deactivated"
              (click)="addBundle($event, selectedPartner)"
              data-cy="edit-bundles-btn"
            >
              <nb-icon icon="cube-outline" />
            </button>
          </div>
        </div>
        <hr class="mt-0" />
        <div class="row">
          <div class="col-12">
            <div class="row align-items-center mb-2">
              <div class="col-12">
                <app-partner-bundle-list [selectedPartner]="selectedPartner" />
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row mb-1 mt-3 align-items-end">
        <p
          class="caption mb-0 col-9"
          translate="partnersPage.titleDoctors"
          [translateParams]="{ partner: selectedPartner.name }"
        ></p>
        <div class="text-end col-3">
          <button
            nbButton
            class="btn-doctor-add p-1"
            (click)="onEditEmployees($event, selectedPartner)"
            nbTooltip="{{ 'partnersPage.tooltips.editEmployees' | translate }}"
            nbTooltipPlacement="left"
            status="primary"
            size="small"
            type="submit"
            data-cy="edit-employees-btn"
          >
            <nb-icon icon="people-outline"></nb-icon>
          </button>
        </div>
      </div>
      <hr class="mt-0" />
      <div class="row">
        <div class="col-12">
          <div class="row align-items-center mb-2 mt-2">
            <div class="col-12">
              <app-partners-doctors-list [partnerId]="selectedPartner.id">
              </app-partners-doctors-list>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col-12 d-flex flex-row-reverse">
          <button nbButton (click)="close()" data-cy="close-btn">Close</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-container>

<ng-template #noItem>—</ng-template>
