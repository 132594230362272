import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  FIRST_LEVEL_PARTNER_TYPES,
  NIFTY_APPLICATIONID_KEY,
} from '@app/app.constants';
import { AccountManagerState } from '@app/modules/@core/store/account-managers/account-managers.state';
import { PantheonState } from '@app/modules/@core/store/pantheon/pantheon.state';
import { PartnerDetailsComponent } from '@app/modules/partners/components/partner-details/partner-details.component';
import {
  AccountManager,
  Country,
  KitTypes,
  Language,
  ModalMode,
  NotificationProfileModel,
  Partner,
  PartnerType,
  PaymentTypes,
  Subject,
  TemplateType,
} from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { CountriesState } from '@core/store/countries/countries.state';
import { LanguageState } from '@core/store/languages/languages.state';
import { UpdateNotificationProfilesAction } from '@core/store/notification-profiles/notification-profiles.actions';
import { NotificationProfileState } from '@core/store/notification-profiles/notification-profiles.state';
import { PartnerTypeState } from '@core/store/partner-types/partner-types.state';
import {
  AddPartnerAction,
  UpdatePartnerAction,
  UpdatePartnerCountryAction,
} from '@core/store/partners/partners.actions';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  OperatorFunction,
  ReplaySubject,
  combineLatest,
  firstValueFrom,
  of,
} from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { debounceTime, map, skip, startWith, take, tap } from 'rxjs/operators';
import { Util } from 'src/app/modules/@core/utils/core.util';
import { PROPERTY_KEYS } from '../../../../app.constants';
import { PaymentType } from '../../../@core/core.types';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { PartnersUtilsService } from '../../partners-utils.service';
import { arrayGroupHasValueValidator } from './validators/array-group-has-value.validator';

const PlasmaLabLocations = [
  {
    value: 'Mexico',
    label: 'Mexico',
  },
  {
    value: '',
    label: 'Not Required',
  },
];

@UntilDestroy()
@Component({
  selector: 'app-partner-dialog',
  templateUrl: 'partner-dialog.component.html',
  styleUrls: ['partner-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerDialogComponent implements OnInit {
  @Select(LanguageState.getLanguages) languages$: Observable<Language[]>;
  @Select(PartnerTypeState.getFirstLevelPartnerTypes)
  firstLevelPartnersTypes$: Observable<PartnerType[]>;
  @Select(PantheonState.getSubjectsList)
  subjects$: Observable<Subject[]>;
  @ViewChild('content') modalContent: TemplateRef<string>;
  subjects: Subject[] = [];
  keyAccountManagers$: Observable<AccountManager[]>;
  partnerCountry$ = new ReplaySubject<string>(1);
  countriesFiltered$: Observable<Country[]>;
  hasPartnerPaymentTypesPermission$: Observable<boolean>;
  paymenttypeErrorMessage$ = new BehaviorSubject<string>('');
  selectedPaymenttypes$ = new BehaviorSubject<string>('');
  isLoading$ = new BehaviorSubject<boolean>(false);

  filteredSubjects$: Observable<Subject[]>;
  externalIdChange$ = new BehaviorSubject<any>('');

  formDirty: boolean;

  partnerForm: any = null;
  propertiesForm: any = null;
  propertiesFormOrder: string[];
  addressForm: any = null;
  kitSupplyForm: FormArray = null;

  modalMode: ModalMode;
  editItem: Partner;
  invalidRegion: boolean;
  detailsDialogRef: NbDialogRef<PartnerDetailsComponent>;

  partnerTypes$: Observable<PartnerType[]>;
  language$ = new UntypedFormControl('');

  PaymentTypes = PaymentTypes;
  plasmaLabLocations = PlasmaLabLocations;

  regionsByCountry$: Observable<string[]> = new BehaviorSubject<string[]>([]);

  @Select(AccountManagerState.getAccountManagersList)
  private allKeyAccountManagers$: Observable<AccountManager[]>;

  private log = new Logger(this.constructor.name);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private dialogRef: NbDialogRef<any>,
    private partnerUtils: PartnersUtilsService,
    private authService: AuthenticationService,
    private dialogService: NbDialogService
  ) {
    this.countriesFiltered$ = this.partnerUtils.filterPartnerCountries$();
  }

  get modalTitle() {
    if (this.modalMode === ModalMode.Create) {
      return 'partnersPage.partnersForm.createPartnerTitle';
    } else if (this.modalMode === ModalMode.Edit) {
      return 'partnersPage.partnersForm.editPartnerTitle';
    }
    return '';
  }

  get closeModalTitle() {
    if (this.modalMode === ModalMode.Create) {
      return 'common.buttons.btnCreate';
    } else if (this.modalMode === ModalMode.Edit) {
      return 'common.buttons.btnUpdate';
    }
    return '';
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('modalMode')
  set _modalMode(modalMode: ModalMode) {
    this.modalMode = modalMode;
  }

  filterSubjects: OperatorFunction<string, readonly Subject[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      map((text: string) =>
        this.subjects.filter((subject) =>
          this.formatSubjectLabel(subject)
            .toLowerCase()
            .includes((text + '').toLowerCase())
        )
      )
    );

  subjectFormatter = (selected: Subject) => {
    const sub = this.subjects.find((subject) => subject.id === selected.id);
    return sub ? `[${sub.id}] ${sub.subjectName}` : '';
  };

  subjectResultFormatter = (sub: Subject) => `[${sub.id}] ${sub.subjectName}`;

  ngOnInit(): void {
    this.subjects$.subscribe((subjects) => (this.subjects = subjects));
    this.log.debug('ngOnInit');

    this.hasPartnerPaymentTypesPermission$ =
      this.authService.hasRequiredPermission$('partners.edit.paymentType');

    this.partnerCountry$.next(
      this.editItem ? this.editItem.address.country : null
    );
    this.isLoading$.next(false);
    this.createForm(this.modalMode === ModalMode.Edit ? this.editItem : null);

    this.filteredSubjects$ = this.cp(
      PROPERTY_KEYS.ExternalId
    ).valueChanges.pipe(
      startWith(''),
      map((filterString: string) =>
        this.subjects.filter((subject) =>
          this.formatSubjectLabel(subject)
            .toLowerCase()
            .includes((filterString + '').toLowerCase())
        )
      )
    );

    this.partnerTypes$ = this.firstLevelPartnersTypes$.pipe(
      map((partnerTypes) => {
        if (partnerTypes && partnerTypes.length) {
          const partnerPartnerTypeId = this.editItem
            ? this.editItem.partnerTypeId
            : null;
          return partnerTypes.filter(
            (pt) =>
              pt.id === partnerPartnerTypeId ||
              [
                FIRST_LEVEL_PARTNER_TYPES.private,
                FIRST_LEVEL_PARTNER_TYPES.privateClinic,
                FIRST_LEVEL_PARTNER_TYPES.public,
                FIRST_LEVEL_PARTNER_TYPES.publicHospital,
              ].indexOf(pt.type) > -1
          );
        }
        return [];
      })
    );

    this.partnerCountry$
      .pipe(
        skip(1),
        switchMap((countryName) =>
          this.store
            .select(CountriesState.getCountryByName)
            .pipe(map((getCountryByName) => getCountryByName(countryName)))
        ),
        untilDestroyed(this)
      )
      .subscribe((country) => {
        if (country) {
          this.partnerForm.get('onlineReport').patchValue(country.onlineReport);
        }
      });
  }

  public onFocusTypeahead(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  createForm(partner?: Partner) {
    this.log.debug('createForm');

    let externalIdProp;
    let ratingProp;
    let addressAdditionalInfoProp;
    let addressRegionProp;
    let isPartnerOnlineProp;
    let plasmaLabLocationProp;

    this.keyAccountManagers$ = this.getAccountManagersByCountry$();

    if (partner) {
      this.selectedPaymenttypes$.next(partner.paymentTypes.join(', '));

      if (partner.address != null && partner.address.country) {
        this.getRegionsByCountry(partner.address.country);
      }

      plasmaLabLocationProp = partner.properties.find(
        (p) =>
          p.key.toLowerCase() === PROPERTY_KEYS.PlasmaLabLocation.toLowerCase()
      );
      plasmaLabLocationProp = plasmaLabLocationProp
        ? plasmaLabLocationProp.value
        : '';

      ratingProp = partner.properties.find(
        (p) => p.key.toLowerCase() === PROPERTY_KEYS.Rating.toLowerCase()
      );
      ratingProp = ratingProp ? ratingProp.value : 0;

      addressAdditionalInfoProp = partner.properties.find(
        (p) =>
          p.key.toLowerCase() === PROPERTY_KEYS.AdditionalInfo.toLowerCase()
      );
      addressAdditionalInfoProp = addressAdditionalInfoProp
        ? addressAdditionalInfoProp.value
        : '';

      addressRegionProp = partner.properties.find(
        (p) => p.key.toLowerCase() === PROPERTY_KEYS.Region.toLowerCase()
      );
      addressRegionProp = addressRegionProp ? addressRegionProp.value : '';

      externalIdProp = partner.properties.find(
        (p) => p.key.toLowerCase() === PROPERTY_KEYS.ExternalId.toLowerCase()
      );
      externalIdProp = externalIdProp
        ? this.subjects.find(
            (subject) => subject.id === parseInt(externalIdProp.value, 10)
          )
        : {};

      isPartnerOnlineProp = partner.properties.find(
        (p) =>
          p.key.toLowerCase() === PROPERTY_KEYS.IsOnlinePartner.toLowerCase()
      );
      isPartnerOnlineProp = isPartnerOnlineProp
        ? isPartnerOnlineProp.value === true ||
          isPartnerOnlineProp.value === 'True'
        : false;
    }

    this.propertiesFormOrder = [
      PROPERTY_KEYS.ExternalId,
      PROPERTY_KEYS.Rating,
      PROPERTY_KEYS.AdditionalInfo,
      PROPERTY_KEYS.Region,
      PROPERTY_KEYS.IsOnlinePartner,
      PROPERTY_KEYS.PlasmaLabLocation,
    ];
    this.propertiesForm = this.formBuilder.array([
      this.formBuilder.group({
        key: [PROPERTY_KEYS.ExternalId],
        value: [partner ? externalIdProp : ''],
      }),
      this.formBuilder.group({
        key: [PROPERTY_KEYS.Rating],
        value: [
          partner ? ratingProp : '',
          [Validators.compose([Validators.min(0), Validators.max(5)])],
        ],
      }),
      this.formBuilder.group({
        key: [PROPERTY_KEYS.AdditionalInfo],
        value: [partner ? addressAdditionalInfoProp : ''],
      }),
      this.formBuilder.group({
        key: [PROPERTY_KEYS.Region],
        value: [partner ? addressRegionProp : ''],
      }),
      this.formBuilder.group({
        key: [PROPERTY_KEYS.IsOnlinePartner],
        value: [partner ? isPartnerOnlineProp : false],
      }),
      this.formBuilder.group({
        key: [PROPERTY_KEYS.PlasmaLabLocation],
        value: [partner ? plasmaLabLocationProp : ''],
      }),
    ]);

    this.kitSupplyForm = this.formBuilder.array([]);

    Object.values(KitTypes).map((kitType: string) => {
      if (kitType !== KitTypes.PremiumPro)
        this.kitSupplyForm.push(
          this.formBuilder.group({
            kitType: [kitType],
            supply: new FormControl<number>(
              partner && partner?.kitSupply
                ? partner.kitSupply.find(
                    (kitSupply) => kitSupply.kitType === kitType
                  )?.supply || 0
                : 0,
              [Validators.min(0), Validators.required]
            ),
          })
        );
    });

    const id = Util.CreateGuid();
    this.partnerForm = this.formBuilder.group(
      Object.assign(
        {
          id: [
            partner ? partner.id : id,
            [Validators.compose([Validators.required])],
          ],
          partnerId: [
            partner ? partner.id : id,
            [Validators.compose([Validators.required])],
          ],
          name: [
            partner ? partner.name : '',
            [Validators.compose([Validators.required])],
          ],
          email: [
            partner ? partner.email : '',
            [Validators.required, Validators.email],
          ],
          phoneNumber: [partner ? partner.phoneNumber : ''],
          languageIds: [[], Validators.required],
          paymentTypes: [
            partner ? partner.paymentTypes : [],
            [Validators.compose([Validators.required])],
          ],
          partnerTypeId: [
            partner ? (partner as any).partnerTypeId : '',
            [Validators.compose([Validators.required])],
          ],
          accountManagerId: [
            partner ? (partner as any).accountManagerId : '',
            [Validators.compose([Validators.required])],
          ],
          addressStreet: [
            partner && partner.address ? partner.address.street : '',
          ],
          addressZip: [partner && partner.address ? partner.address.zip : ''],
          addressCity: [partner && partner.address ? partner.address.city : ''],
          addressCountry: [
            partner && partner.address ? partner.address.country : '',
            [Validators.required],
          ],
          barcodeRanges: [[]],
          notes: [partner ? partner.notes : '', []],
          onlineReport: [partner ? partner.onlineReport : false],
          properties: this.propertiesForm,
          kitSupply: this.kitSupplyForm,
        },
        partner
          ? {
              productConfigurations: [partner.productConfigurations],
              bundleConfigurations: [partner.bundleConfigurations],
              subPartners: [partner.subPartners],
              address: [partner.address],
              usedForTesting: [(partner as any).usedForTesting],
              applications: [(partner as any).applications],
              userAccounts: [(partner as any).userAccounts],
              languageIds: [partner.languageIds],
              registered: [(partner as any).registered],
              policyIds: [(partner as any).policyIds],
              lastSample: [(partner as any)?.lastSample],
            }
          : {
              applicationId: [
                NIFTY_APPLICATIONID_KEY,
                [Validators.compose([Validators.required])],
              ],
              productConfigurations: [[]],
              subPartners: [[]],
            }
      )
    );

    const getRegionsByCountry = (country: string) => {
      this.getRegionsByCountry(country);
      this.cp(PROPERTY_KEYS.Region).setValue('');
    };

    this.c('addressCountry')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap((country: string) => {
          getRegionsByCountry(country);
          this.partnerCountry$.next(country);
        })
      )
      .subscribe();

    this.language$.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((l) => this.c('languageIds').setValue([l]))
      )
      .subscribe();

    if (partner && partner.languageIds) {
      const [selectedLanguage] = partner.languageIds;
      this.language$.setValue(selectedLanguage);
    }

    this.c('paymentTypes')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap((paymentTypes: string[]) => {
          const selectedPayments = paymentTypes.join(', ');
          this.selectedPaymenttypes$.next(selectedPayments);
          this.validatePaymentTypes(paymentTypes);
        })
      )
      .subscribe();

    this.cp('IsOnlinePartner')
      .valueChanges.pipe(startWith(this.cp('IsOnlinePartner').value))
      .subscribe((isOnlinePartner: boolean) => {
        if (isOnlinePartner) {
          this.kitSupplyForm.setValidators(null);
        } else {
          this.kitSupplyForm.setValidators(
            arrayGroupHasValueValidator('supply')
          );
        }

        this.kitSupplyForm.updateValueAndValidity();
      });
  }

  protected c(control: string): any {
    return this.partnerForm.controls[control];
  }

  protected cp(control: string): any {
    const controlIndex = this.propertiesFormOrder.indexOf(control);
    return this.propertiesForm.controls[controlIndex].controls.value;
  }

  dismiss() {
    this.dialogRef.close();
  }

  async close() {
    this.log.debug('save/edit partner');
    const formValue = this.partnerForm.value;

    Util.MarkFormGroupTouched(this.partnerForm);

    if (
      this.partnerForm.invalid ||
      !(await this.validatePaymentTypes(formValue.paymentTypes).toPromise())
    ) {
      return false;
    }

    const externalIdProp = formValue.properties.find(
      (prop) => prop.key === PROPERTY_KEYS.ExternalId
    );
    if (externalIdProp) {
      externalIdProp.value = externalIdProp.value?.id ?? null;
    }

    const region = formValue.properties.Region;
    const regionsByCountry = await firstValueFrom(this.regionsByCountry$);
    if (
      formValue.addressCountry != null &&
      region != null &&
      regionsByCountry != null &&
      !regionsByCountry.includes(region)
    ) {
      this.invalidRegion = true;
      return false;
    }
    this.isLoading$.next(true);

    let saveItem$: Observable<any>;
    const selectedPartnerTypes = formValue.paymentTypes;

    if (this.modalMode === ModalMode.Edit) {
      saveItem$ = this.store
        .dispatch(new UpdatePartnerAction(formValue, selectedPartnerTypes))
        .pipe(
          switchMap(() => this.updateNotificationProfiles(formValue)),
          switchMap(() =>
            this.store.dispatch(
              new UpdatePartnerCountryAction(
                formValue.addressCountry,
                formValue.id
              )
            )
          )
        );
    } else {
      saveItem$ = this.store.dispatch(
        new AddPartnerAction(formValue, selectedPartnerTypes)
      );
    }

    await saveItem$
      .pipe(
        take(1),
        tap(() => {
          if (this.detailsDialogRef) {
            this.detailsDialogRef.close();
          }
          this.isLoading$.next(false);
          this.dismiss();
          this.dialogService.open(PartnerDetailsComponent, {
            context: {
              partnerId: formValue.id,
            },
          });
        })
      )
      .toPromise();

    this.isLoading$.next(false);
  }

  formatPartnerLabel(partner: PartnerType) {
    // Overriding 'referral' to 'Referring Physician" here, as changing constant would break numerious other things and need BE response change.
    if (partner.type === FIRST_LEVEL_PARTNER_TYPES.referral) {
      return 'Referring Physician';
    }
    return `${partner.type}`;
  }

  formatAccountManagerLabel(accountManager: AccountManager) {
    return `${accountManager.name}`;
  }

  formatSubjectLabel(subject: Subject) {
    return `[${subject.id}] ${subject.subjectName}`;
  }

  getRegionsByCountry(country: string) {
    this.regionsByCountry$ = this.store
      .select(CountriesState.getCountryRegions)
      .pipe(map((regionsByCountry) => regionsByCountry(country)));
  }

  getAccountManagersByCountry$(): Observable<AccountManager[]> {
    return combineLatest([
      this.partnerCountry$,
      this.allKeyAccountManagers$,
    ]).pipe(
      map(([country, accountManagers]) => {
        if (country) {
          return accountManagers.filter((am) =>
            am.countries.find((c) => c.toLowerCase() === country.toLowerCase())
          );
        } else {
          return accountManagers;
        }
      })
    );
  }

  private getNotificationProfiles(
    doctors
  ): Observable<NotificationProfileModel[]> {
    let profilesIds = [];
    for (const doctor of doctors) {
      if (doctor?.notificationProfiles?.length) {
        profilesIds = profilesIds.concat(doctor.notificationProfiles);
      }
    }
    return this.store
      .select(NotificationProfileState.getNotificationProfiles)
      .pipe(
        map((filterById) => filterById(profilesIds)),
        first()
      );
  }

  private updateNotificationProfilesTemplates(
    formValue,
    profiles
  ): Observable<void> {
    const resultReferenceLinkTemplates = profiles
      .filter((profile) => !!profile)
      .filter(
        (profile) => profile.name === TemplateType.CustomerInviteToOnlineReport
      );
    const nps = [];
    for (const np of resultReferenceLinkTemplates) {
      nps.push({
        id: np.id,
        sender: np.sender,
        senderName: np.senderName,
        recipients: np.recipients,
        name: np.name,
        templateId: this.partnerUtils.getResultReferenceLinkTemplateByLanguage(
          this.store.selectSnapshot(LanguageState.getLanguageById)(
            formValue.languageIds[0]
          ).name
        ),
        applicationId: formValue.applicationId,
      });
    }

    return this.store.dispatch(new UpdateNotificationProfilesAction(nps));
  }

  private updateNotificationProfiles(formValue): Observable<void> {
    return this.partnerUtils.getPartnerDoctors$(formValue).pipe(
      first(),
      switchMap((doctors) => this.getNotificationProfiles(doctors)),
      switchMap((profiles) =>
        this.updateNotificationProfilesTemplates(formValue, profiles)
      )
    );
  }

  private validatePaymentTypes(
    selectedPaymentTypes: string[]
  ): Observable<boolean> {
    this.log.debug('validate paymenttype');
    this.paymenttypeErrorMessage$.next('');

    if (selectedPaymentTypes.length === 1) {
      if (
        selectedPaymentTypes.includes(PaymentType.Customer2) ||
        selectedPaymentTypes.includes(PaymentType.Online2)
      ) {
        this.paymenttypeErrorMessage$.next(
          'partnersPage.errors.partnerTypePartnerIsRequired'
        );
        return of(false);
      }
      return of(true);
    }

    if (
      (selectedPaymentTypes.includes(PaymentType.Customer1) &&
        selectedPaymentTypes.includes(PaymentType.Customer2)) ||
      (selectedPaymentTypes.includes(PaymentType.Online1) &&
        selectedPaymentTypes.includes(PaymentType.Online2))
    ) {
      this.paymenttypeErrorMessage$.next(
        'partnersPage.errors.incorrectPymentTypeConfiguration'
      );
      return of(false);
    } else if (
      (selectedPaymentTypes.includes(PaymentType.Customer2) ||
        selectedPaymentTypes.includes(PaymentType.Online2)) &&
      !selectedPaymentTypes.includes(PaymentType.Partner)
    ) {
      this.paymenttypeErrorMessage$.next(
        'partnersPage.errors.partnerTypePartnerIsRequired'
      );
      return of(false);
    } else {
      return of(true);
    }
  }
}
