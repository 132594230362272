import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormGroup,
  NgModel,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import {
  delay,
  filter,
  finalize,
  first,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  Partner,
  Product,
  ProductConfiguration,
} from '../../../@core/core.types';
import { ProductConfigurationsState } from '../../../@core/store/product-configuration/product-configuration.state';
import { ProductsListState } from '../../../@core/store/products/products.state';
import { ConfirmCloseReason } from '../../../@shared/shared.types';
import {
  AddProductConfiguration,
  RemoveProductConfiguration,
} from '../../store/partner-product-configurations/partner-product-configurations.action';
import { PartnerProductConfigurationsState } from '../../store/partner-product-configurations/partner-product-configurations.state';

interface ProductPair {
  productConfiguration: ProductConfiguration;
  product: Product;
}

@Component({
  selector: 'app-partner-product-dialog',
  templateUrl: 'partner-product-dialog.component.html',
  styleUrls: ['partner-product-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProductDialogComponent implements OnInit {
  @ViewChild('selectProduct') selectProduct: NgModel;
  @Select(PartnerProductConfigurationsState.getProductConfigurations)
  getProductConfigurations$: Observable<string[]>;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;

  @Input() public partner: Partner;

  protected dataTableConfiguration: DataTableConfiguration;
  protected isLoading = false;
  protected productForm: FormGroup = null;
  protected countryProducts$: Observable<Product[]>;

  private rows: Observable<ProductPair[]> = null;

  private destroyRef = inject(DestroyRef);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store,
    private dialogRef: NbDialogRef<any>,
    private translateService: TranslateService,
    private confirmService: ConfirmService
  ) {
    this.rows = this.getProductConfigurations$.pipe(
      takeUntilDestroyed(this.destroyRef),
      delay(200),
      switchMap((productConfigurationIds: string[]) =>
        this.store
          .select(ProductConfigurationsState.getProductConfigurationByIds)
          .pipe(
            map((getByIds) => getByIds(productConfigurationIds)),
            switchMap((productConfigurations: ProductConfiguration[]) =>
              this.store.select(ProductsListState.getProduct).pipe(
                map((getProduct) =>
                  productConfigurations
                    .map((pc: ProductConfiguration) => ({
                      productConfiguration: pc,
                      product: getProduct(pc.productId),
                    }))
                    .sort((a, b) =>
                      a.product.name.toLowerCase() <
                      b.product.name.toLowerCase()
                        ? -1
                        : 1
                    )
                )
              )
            )
          )
      ),
      tap(() => this.cd.markForCheck())
    );
  }

  ngOnInit() {
    this.isLoading = false;
    this.createForm();

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          name: 'partnersPage.tooltips.removeProductFromPartner',
          action: this.removeProduct.bind(this),
          icon: 'close-outline',
          type: DataTableActionType.BUTTON,
          permission: 'partners.details.products.remove',
        },
      ],
      columns: [
        {
          label: 'Product name',
          propertyTemplate: this.nameTemplate,
        },
      ],
      rows$: this.rows,
    };

    this.getProductList();
  }

  protected c(control: string): AbstractControl {
    return this.productForm.controls[control];
  }

  protected dismiss() {
    this.dialogRef.close();
  }

  protected addProduct(event: MouseEvent, product: Product) {
    event.preventDefault();
    if (this.isLoading) {
      return false;
    }
    this.isLoading = true;
    this.store
      .dispatch(new AddProductConfiguration(product))
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.cd.markForCheck();
          this.isLoading = false;
          this.selectProduct.reset(null);
          this.productForm.reset();
        })
      )
      .subscribe();
  }

  private removeProduct(event: MouseEvent, productPair: ProductPair) {
    event.preventDefault();
    if (this.isLoading) {
      return false;
    }

    this.confirmService
      .confirm({
        message: this.translateService.instant(
          'partnersPage.confirmProductRemoveMessage',
          { product: productPair?.product?.name }
        ),
        title: 'partnersPage.confirmProductRemoveTitle',
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        first(),
        switchMap((confirmOpt) => {
          if (confirmOpt.closeReason === ConfirmCloseReason.Yes) {
            this.isLoading = true;
            return this.store
              .dispatch(
                new RemoveProductConfiguration(
                  productPair.productConfiguration.id
                )
              )
              .pipe(
                takeUntilDestroyed(this.destroyRef),
                finalize(() => {
                  this.cd.markForCheck();
                  this.isLoading = false;
                })
              );
          }
        })
      )
      .subscribe();
  }

  protected isProductAvailable(productId: string): Observable<boolean> {
    return this.store.select(ProductsListState.getCountryProductList).pipe(
      map((productsByCountry) =>
        productsByCountry(
          this.partner.address ? this.partner.address.country : null
        )
      ),
      take(1),
      map(
        (countryProducts: Product[]) =>
          !!countryProducts.find((product) => product.id === productId)
      )
    );
  }

  private createForm() {
    this.productForm = this.formBuilder.group({
      product: ['', [Validators.required]],
    });
  }

  private getProductList() {
    this.countryProducts$ = this.store
      .select(ProductsListState.getCountryActiveProductList)
      .pipe(
        map((countryFilter) => countryFilter(this.partner.address.country)),
        switchMap((products: Product[]) =>
          this.getProductConfigurations$.pipe(
            filter(
              (productConfigurations: string[]) => !!productConfigurations
            ),
            switchMap((productConfigurations: string[]) => {
              if (!productConfigurations) {
                return of([]);
              }
              if (productConfigurations && productConfigurations.length <= 0) {
                return of(products);
              }
              return forkJoin(
                productConfigurations.map((productConfigurationId) =>
                  this.store
                    .select(
                      ProductConfigurationsState.getProductConfigurationById
                    )
                    .pipe(
                      map((filterByProductConfigurationId) =>
                        filterByProductConfigurationId(productConfigurationId)
                      ),
                      filter((pc: ProductConfiguration) => !!pc),
                      take(1)
                    )
                )
              ).pipe(
                map((pcs: ProductConfiguration[]) => {
                  const items = [];
                  for (const product of products) {
                    if (
                      pcs.findIndex((pc) => pc.productId === product.id) < 0
                    ) {
                      items.push(product);
                    }
                  }

                  return items;
                })
              );
            })
          )
        )
      );
  }
}
