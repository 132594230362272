<nb-card style="min-width: 800px">
  <nb-card-header>
    <span translate="notificationProfilesPage.pageTitle"></span>
    <nb-icon (click)="dismiss()" icon="close" data-cy="close-icon"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-12">
        <app-data-table
          [configuration]="dataTableConfiguration"
          [loading]="isLoading$ | async"
        >
        </app-data-table>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="dismiss()" data-cy="close-btn">
      {{ 'common.buttons.btnClose' | translate }}
    </button>
  </nb-card-footer>
</nb-card>

<ng-template #nameTemplate let-row="row">
  <span class="caption" *ngIf="row.name">
    {{ row.name }} <b> {{ getTemplateLanguageString(row) }} </b></span
  >
</ng-template>
<ng-template #recipientsTemplate let-row="row">
  <span class="caption" *ngIf="row?.recipients">
    {{ getRecipientsString(row.recipients) }}
  </span>
</ng-template>
