import { FormArray, FormGroup, ValidatorFn } from '@angular/forms';

export function arrayGroupHasValueValidator(controlName: string): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    const valid = { arrayGroupHasValue: true };

    formArray.controls.forEach((group: FormGroup) => {
      if (group.get(controlName).value) {
        valid.arrayGroupHasValue = false;
      }
    });

    return valid.arrayGroupHasValue ? valid : null;
  };
}
