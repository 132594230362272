<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ 'partnersPage.productForm.editProducts' | translate }}
    </p>
    <nb-icon (click)="dismiss()" icon="close" data-cy="close-icon"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="row mb-1 align-items-end">
      <p
        class="caption mb-1 col-9"
        translate="partnersPage.titleProducts"
        [translateParams]="{ partner: partner.name }"
      ></p>
    </div>
    <hr class="mt-0 mb-3" />
    <form [formGroup]="productForm" novalidate>
      <div class="row">
        <div class="form-group col-sm-12">
          <div class="d-flex">
            <div class="flex-fill">
              <ng-container
                *ngIf="countryProducts$ | async as products; else loading"
              >
                <ng-template [ngIf]="products.length > 0">
                  <nb-select
                    #selectProduct
                    fullWidth
                    id="product"
                    placeholder="{{
                      'partnersPage.partnersForm.placeholders.SelectProduct'
                        | translate
                    }}"
                    formControlName="product"
                    [status]="
                      c('product').dirty
                        ? c('product').invalid
                          ? 'danger'
                          : 'success'
                        : ''
                    "
                    data-cy="product-select"
                  >
                    <nb-option
                      *ngFor="let product of products"
                      [value]="product"
                      >{{ product.name }}</nb-option
                    >
                  </nb-select>
                </ng-template>
                <ng-template [ngIf]="products.length === 0">
                  <nb-select
                    disabled
                    fullWidth
                    placeholder="{{
                      'partnersPage.partnersForm.placeholders.noProductsAvailble'
                        | translate
                    }}"
                  >
                  </nb-select>
                </ng-template>
              </ng-container>
            </div>
            <button
              nbButton
              class="ms-2"
              (click)="addProduct($event, c('product').value)"
              [disabled]="
                isLoading ||
                ((countryProducts$ | async) &&
                  (countryProducts$ | async)?.length === 0) ||
                c('product').invalid
              "
              nbTooltip="{{
                'partnersPage.tooltips.addProductToPartner' | translate
              }}"
              nbTooltipPlacement="left"
              status="primary"
              type="submit"
              data-cy="add-product-btn"
            >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>
          </div>
          <div class="row align-items-center mb-2">
            <div
              class="col-sm-12"
              *ngIf="
                (countryProducts$ | async) &&
                (countryProducts$ | async)?.length > 0
              "
            >
              <ng-container
                *ngIf="c('product').invalid && c('product').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('product').errors?.required"
                  translate="partnersPage.errors.productIsRequired"
                >
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <app-data-table
            [configuration]="dataTableConfiguration"
          ></app-data-table>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="dismiss()" data-cy="close-btn">Close</button>
  </nb-card-footer>
</nb-card>

<ng-template #loading>
  <div class="pt-1 loading"></div>
</ng-template>

<ng-template #nameTemplate let-row="row">
  {{ row.product?.name }}
  @if (row.product?.deactivated) {
    <span
      class="badge bg-warning ms-2"
      [nbTooltip]="'partnersPage.tooltips.productDeactivated' | translate"
      >{{ 'partnersPage.productDeactivated' | translate }}
    </span>
  }

  @if (!(isProductAvailable(row.product?.id) | async)) {
    <span
      class="badge bg-danger ms-2"
      [nbTooltip]="'partnersPage.tooltips.productNotAvailable' | translate"
      >{{ 'partnersPage.productNotAvailable' | translate }}
    </span>
  }
</ng-template>

<ng-template #loadingColumn> -- </ng-template>
