<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">Edit partner employees</p>
    <nb-icon (click)="close()" icon="close" data-cy="close-icon"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="row mb-1 align-items-end">
      <p
        class="caption mb-0 col-9"
        translate="partnersPage.titleDoctors"
        [translateParams]="{ partner: partner.name }"
      ></p>
    </div>
    <hr class="mt-0 mb-2" />
    <app-partners-doctors-list
      [partnerId]="partner.id"
      [withActions]="true"
    ></app-partners-doctors-list>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="close()" data-cy="close-btn">Close</button>
  </nb-card-footer>
</nb-card>
